<template>
  <div id="category">
    <div class="metaDesc" v-html="categoryMetaDescription" />
    <SfBreadcrumbs class="breadcrumbs desktop-only" :breadcrumbs="breadcrumbs">
      <template #link="{breadcrumb}">
        <router-link :to="breadcrumb.route.link" class="sf-breadcrumbs__breadcrumb" target="_self">
          {{ breadcrumb.text }}
        </router-link>
      </template>
    </SfBreadcrumbs>
    <div class="navbar section">
      <div class="navbar__aside desktop-only">
        <SfHeading :level="3" :title="$t('Categories')" class="navbar__title" />
      </div>
      <div class="navbar__main">
        <SfButton
          class="navbar__filters-button sf-button--text"
          @click="isFilterSidebarOpen = true"
        >
          <AIcon size="24px" class="navbar__filters-icon" icon="filter" />
          {{ $t("Filters") }}
          <template v-if="activeFiltersCount">
            ({{ activeFiltersCount }})
          </template>
        </SfButton>
        <template v-if="activeFiltersCount">
          <span>&nbsp;&mdash;&nbsp;</span>
          <button @click="clearAllFilters" class="sf-button sf-button--text navbar__filters-clear-all">
            {{ $t('Clear all') }}
          </button>
        </template>
        <div class="navbar__sort">
          <span class="navbar__label desktop-only">{{ $t("Sort By") }}:</span>
          <SfComponentSelect
            class="navbar__select sort-by"
            ref="SortBy"
            :key="categoryId"
            v-model="selectedSortOrder"
          >
            <SfComponentSelectOption
              v-for="option in sortOptions"
              :key="option.id"
              :value="option.id"
              class="sort-by__option"
            >
              {{ option.frontend_label || $t(option.label) }}
            </SfComponentSelectOption>
          </SfComponentSelect>
        </div>
        <SfButton
          class="navbar__filters-button sort-by-btn sf-button--text smartphone-only"
          @click="$refs.SortBy.toggle()"
        >
          {{ $t('Sort By') }}
          <ASortIcon />
        </SfButton>
        <div class="navbar__counter">
          <span class="navbar__label desktop-only">
            {{ $t("Products found") }}:
          </span>
          <strong class="desktop-only">{{ getCategoryProductsTotal }}</strong>
        </div>
      </div>
    </div>
    <div class="main section" ref="productsList">
      <div class="sidebar desktop-only">
        <SfAccordion show-chevron>
          <SfAccordionItem
            v-for="category in categories"
            :key="category.id"
            :header="category.name"
          >
            <template #header="{ header, isOpen, accordionClick }">
              <SfButton
                :aria-pressed="isOpen.toString()"
                :aria-expanded="isOpen.toString()"
                :class="{ 'is-open': isOpen }"
                class="sf-button--pure sf-accordion-item__header"
                @click="accordionClick"
              >
                {{ header }}
                <slot name="additional-info" />
                <SfChevron
                  tabindex="0"
                  class="sf-accordion-item__chevron"
                  :class="{ 'sf-chevron--top': isOpen }"
                />
              </SfButton>
            </template>
            <SfList class="list">
              <SfListItem v-for="item in category.items" :key="item.id" class="list__item">
                <SfLink :link="item.link" :class="{'is-active': isCategoryActive(item)}" @click.native="changeCategory(item.link)">
                  <SfMenuItem :label="item.name" :count="item.count" />
                </SfLink>
              </SfListItem>
            </SfList>
          </SfAccordionItem>
        </SfAccordion>
      </div>
      <div class="products">
        <div>
          <div class="category-top-description">
            <h1>
              {{ categoryName }}
            </h1>

            <section v-if="getCurrentCategory.category_cms_block_top_editor">
              <div v-if="currentPage < 2" class="category-top-description" v-html="getCurrentCategory.category_cms_block_top_editor" />
            </section>
          </div>
        </div>
        <SfHeading
          v-if="isCategoryEmpty"
          :title="$t('No products found!')"
          :description="
            $t('Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!')
          "
        />
        <template v-else>
          <lazy-hydrate :trigger-hydration="!loading">
            <transition-group
              appear
              name="products__slide"
              tag="div"
              class="products__grid"
            >
              <OProductCard
                v-for="(product, index) in products"
                :key="`category-product_${product.id}_${index}`"
                :image="product.image"
                :hover-image="product.hover_image"
                :title="product.title"
                :link="product.link"
                :regular-price="product.price.regular"
                :special-price="product.price.special"
                :final-price="product.price.final"
                :max-rating="product.rating.max"
                :score-rating="product.rating.score"
                :reviews-count="product.rating.count"
                :badge-label="product.badge"
                :sku="product.sku"
                :parent-sku="product.parentSku"
                class="products__product-card"
                @click:wishlist="toggleWishlist(fullProducts[index])"
                :is-on-wishlist="isOnWishlist(fullProducts[index])"
                :add-to-cart-disabled="isCartBusy"
                :type-id="product.typeId"
                :badges="product.badges"
                :badges-sort-order="product.badgesSortOrder"
                :percentage-off="product.percentageOff"
                :gtm-custom-dimensions="product.gtmCustomDimensions"
                :gtm-item-list="gtmItemList"
              />
            </transition-group>
          </lazy-hydrate>
          <MPagination
            v-if="totalPages > 1"
            class="products__pagination desktop-only"
            :current="currentPage"
            :total="totalPages"
            :visible="3"
          >
            <template #points>
              <span class="sf-pagination__item points"> … </span>
            </template>
          </MPagination>
          <section v-if="currentPage < 2 && getCurrentCategory.category_cms_block_bottom_editor">
            <div class="bottom-description" id="bottom-description" v-html="getCurrentCategory.category_cms_block_bottom_editor" />
          </section>
        </template>
      </div>
    </div>
    <SfSidebar
      :visible="isFilterSidebarOpen"
      :title="$t('Filters')"
      class="sidebar-filters"
      @close="isFilterSidebarOpen = false"
    >
      <div class="filters">
        <template v-for="(filters, filterType) in availableFilters">
          <SfHeading
            :level="4"
            :title="filterType"
            :key="filterType"
            class="filters__title sf-heading--left"
          />
          <template v-if="filterType === 'color_filter'">
            <div class="filters__colors" :key="filterType + 'filter'">
              <SfColor
                v-for="filter in filters"
                :key="filter.id"
                :color="filter.color"
                :selected="isFilterActive(filter)"
                class="filters__color"
                @click="changeFilter(filter)"
              />
            </div>
          </template>
          <template v-else>
            <SfFilter
              v-for="filter in filters"
              :key="filter.id"
              :label="filter.label"
              :count="filter.count"
              :color="filter.color"
              :selected="isFilterActive(filter)"
              class="filters__item"
              @change="changeFilter(filter)"
            />
          </template>
        </template>
      </div>
      <template #content-bottom>
        <div class="filters__buttons">
          <SfButton
            class="sf-button--full-width"
            @click="isFilterSidebarOpen = false"
          >
            {{ $t("Done") }}
          </SfButton>
          <SfButton
            class="sf-button--full-width filters__button-clear"
            @click="clearAllFilters"
          >
            {{ $t("Clear all") }}
          </SfButton>
        </div>
      </template>
    </SfSidebar>
    <lazy-hydrate when-visible>
      <div class="products-recommended">
        <MRecommendedProducts :header="$t('Recommended to you')" slider-id="top" :products="recommendedCarousel" />
      </div>
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import { mapGetters, mapActions } from 'vuex';
import castArray from 'lodash-es/castArray';
import config from 'config';
import { buildFilterProductsQuery, isServer } from '@vue-storefront/core/helpers';
import i18n from '@vue-storefront/i18n';
import { htmlDecode } from '@vue-storefront/core/filters';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { getSearchOptionsFromRouteParams, getCanonicalOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import { RemoveFromWishlist } from '@vue-storefront/core/modules/wishlist/components/RemoveFromWishlist';
import { AddToWishlist } from '@vue-storefront/core/modules/wishlist/components/AddToWishlist';
import { Logger } from '@vue-storefront/core/lib/logger';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { getTopLevelCategories, prepareCategoryMenuItem, prepareListingProduct } from '~/theme/helpers';
import AIcon from '~/theme/components/atoms/a-icon';
import ASortIcon from '~/theme/components/atoms/a-sort-icon';
import MPagination from '~/theme/components/molecules/m-pagination';
import MRecommendedProducts from '~/theme/components/molecules/m-recommended-products';
import MLoadMore from '~/theme/components/molecules/m-load-more'
import OProductCard from '~/theme/components/organisms/o-product-card';
import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';
import SfList from '@storefront-ui/vue/src/components/organisms/SfList/SfList.vue';
import SfColor from '@storefront-ui/vue/src/components/atoms/SfColor/SfColor.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfChevron from '@storefront-ui/vue/src/components/atoms/SfChevron/SfChevron.vue';
import SfComponentSelect from '@storefront-ui/vue/src/components/molecules/SfComponentSelect/SfComponentSelect.vue';
import SfFilter from '@storefront-ui/vue/src/components/molecules/SfFilter/SfFilter.vue';
import SfSidebar from '@storefront-ui/vue/src/components/organisms/SfSidebar/SfSidebar.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfMenuItem from '@storefront-ui/vue/src/components/molecules/SfMenuItem/SfMenuItem.vue';
import SfAccordion from '@storefront-ui/vue/src/components/organisms/SfAccordion/SfAccordion.vue';
import SfBreadcrumbs from '@storefront-ui/vue/src/components/atoms/SfBreadcrumbs/SfBreadcrumbs.vue';
import { gtmItemLists } from 'src/modules/google-tag-manager/types/gtmEvents.ts';
import { formatCategoryLink, addCanonicalParams } from '@vue-storefront/core/modules/url/helpers';

const THEME_PAGE_SIZE = 36;
const LAZY_LOADING_ACTIVATION_BREAKPOINT = 1024;

const composeInitialPageState = async (store, route, forceLoad = false) => {
  try {
    const filters = getSearchOptionsFromRouteParams(route.params);
    const cachedCategory = store.getters['category-next/getCategoryFrom'](
      route.path
    );
    const currentCategory =
      cachedCategory && !forceLoad
        ? cachedCategory
        : await store.dispatch('category-next/loadCategory', { filters });
    await store.dispatch('category-next/loadCategoryProducts', {
      route,
      page: route.params?.page || 1,
      category: currentCategory,
      pageSize: THEME_PAGE_SIZE
    });
    const breadCrumbsLoader = store.dispatch(
      'category-next/loadCategoryBreadcrumbs',
      {
        category: currentCategory,
        currentRouteName: currentCategory.name,
        omitCurrent: true
      }
    );

    if (isServer) await breadCrumbsLoader;
    catalogHooksExecutors.categoryPageVisited(currentCategory);
  } catch (err) {
    Logger.error(err)()
  }
};

export default {
  name: 'CategoryPage',
  components: {
    LazyHydrate,
    ASortIcon,
    AIcon,
    SfLink,
    SfList,
    SfColor,
    SfButton,
    SfChevron,
    SfComponentSelect,
    SfFilter,
    SfSidebar,
    SfHeading,
    SfMenuItem,
    SfAccordion,
    SfBreadcrumbs,
    MPagination,
    MRecommendedProducts,
    OProductCard
  },
  mixins: [RemoveFromWishlist, AddToWishlist],
  data () {
    return {
      loading: true,
      loadingProducts: false,
      loadingProductsStarted: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      getMoreCategoryProducts: [],
      browserWidth: 0,
      selectedSortOrder: null,
      isFilterSidebarOpen: false,
      unsubscribeFromStoreAction: null,
      aggregations: null,
      gtmItemList: gtmItemLists.CategoryPageListing,
      recommendedCarousel: []
    };
  },
  computed: {
    ...mapGetters({
      getCategorySearchProductsStats: 'category-next/getCategorySearchProductsStats',
      getCurrentSearchQuery: 'category-next/getCurrentSearchQuery',
      getCategoryProducts: 'category-next/getCategoryProducts',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAttributeListByCode: 'attribute/getAttributeListByCode',
      getAvailableFilters: 'category-next/getAvailableFilters',
      getCurrentFilters: 'category-next/getCurrentFilters',
      getSystemFilterNames: 'category-next/getSystemFilterNames',
      getCategories: 'category/getCategories',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent',
      isCartBusy: 'cart/getCartProcessingStatus',
      cartItems: 'cart/getCartItems',
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    landingPage () {
      return this.getCurrentCategory?.landing_page
    },
    categoryId () {
      return this.getCurrentCategory?.id
    },
    categoryName () {
      const categoryName = this.getCurrentCategory?.name;

      let result = categoryName;

      if (this.currentPage > 1) {
        result = i18n.t('{{category}} - Page {{page}}');
        result = result.replace('{{category}}', categoryName);
        result = result.replace('{{page}}', this.currentPage);
      }

      return htmlDecode(result);
    },
    categoryDescription () {
      return this.getCurrentCategory?.description
    },
    categoryMetaDescription () {
      return this.getCurrentCategory?.meta_description
    },
    currentPageMobile () {
      const page = Math.floor(this.getCategorySearchProductsStats.start / this.getCategorySearchProductsStats.perPage) + 1
      return isNaN(page) ? 1 : page
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.includes('category-next.products');
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0;
    },
    isLazyLoadingEnabled () {
      return this.browserWidth < LAZY_LOADING_ACTIVATION_BREAKPOINT;
    },
    breadcrumbs () {
      const categoriesListToHide = ['Default Category']
      return this.getBreadcrumbsRoutes
        .map(route => ({
          text: htmlDecode(route.name),
          route: {
            link: route.route_link
          }
        }))
        .concat({
          text: htmlDecode(this.getBreadcrumbsCurrent)
        })
        .filter(route => !route.text.includes(categoriesListToHide));
    },
    categories () {
      return getTopLevelCategories(this.getCategories)
        .map(category => {
          const viewAllMenuItem = {
            ...category,
            name: i18n.t('View all'),
            position: 0
          };

          const subCategories = category.children_data
            ? category.children_data
              .map(subCategory => prepareCategoryMenuItem(
                this.getCategories.find(category => category.id === subCategory.id)
              ))
              .filter(Boolean)
            : [];

          return {
            ...prepareCategoryMenuItem(category),
            items: [
              { ...prepareCategoryMenuItem(viewAllMenuItem), count: null },
              ...subCategories
                .map((subCategory) => ({ ...subCategory, count: null })) // TODO: remove map, it's set up temporary since count isn't calculate properly
            ]
              .sort((a, b) => a.position - b.position)
          };
        })
        .sort((a, b) => a.position - b.position);
    },
    products () {
      // lazy loading is disabled for desktop screen width (>= 1024px)
      // so products from store have to be filtered out because there could
      // be more than THEME_PAGE_SIZE of them - they could be fetched earlier
      // when lazy loading was enabled
      return this.isLazyLoadingEnabled || this.currentPage === 1
        ? this.getCategoryProducts
          .filter((_, i) => this.isLazyLoadingEnabled || i < THEME_PAGE_SIZE)
          .map(prepareListingProduct)
        : this.getMoreCategoryProducts.map((product, i) => prepareListingProduct(this.fullProducts[i]));
    },
    fullProducts () {
      return this.isLazyLoadingEnabled || this.currentPage === 1
        ? this.getCategoryProducts
          .filter((product, i) => {
            return this.isLazyLoadingEnabled || i < THEME_PAGE_SIZE;
          })
        : this.getMoreCategoryProducts
    },
    totalPages () {
      return Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE);
    },
    sortOrder () {
      let result = (
        this.getCurrentSearchQuery.sort ||
        `${this.getCurrentCategory?.default_sort_by}` ||
        `${config.products.defaultSortBy.attribute}:${config.products.defaultSortBy.order}`
      )

      return result === 'position' ? `category_${this.categoryId}_position` : result
    },
    sortOptions () {
      const simpleSorts = Object.entries(config.products.sortByAttributes || this.getCurrentCategory.available_sort_by).map(attribute => {
        const [key, id] = attribute;
        const label = this.mapSortOptionToLabel(id);

        return { id, label };
      });

      const categorySpecificSort = {
        id: `category_${this.categoryId}_position`,
        label: 'Recommended'
      }

      let sortOptions = [...simpleSorts, categorySpecificSort]

      return sortOptions.filter(sortOption => sortOption.id !== 'position')
    },
    availableFilters () {
      const filters = Object.entries(this.getAvailableFilters || {})
        .filter(([filterType, filters]) => {
          return (
            filters.length && !this.getSystemFilterNames.includes(filterType)
          );
        })
        .reduce((result, [filterType, filters]) => {
          const filterLabel = this.getAttributeListByCode[filterType]?.frontend_label || `${filterType}_filter`
          result[filterLabel] = filters.map(filter => ({
            ...filter,
            count: this.getFilterCount(filter) || '',
            color:
              filterType === 'color'
                ? (config.products.colorMappings &&
                    config.products.colorMappings[filter.label]) ||
                  filter.label
                : undefined
          }));
          return result;
        }, {});
      return filters
    },
    activeFiltersCount () {
      let counter = 0
      Object.keys(this.getCurrentFilters).forEach(key => {
        counter += this.getCurrentFilters[key].length
      })
      return counter
    },
    isFilterActive () {
      return filter =>
        castArray(this.getCurrentFilters[filter.type]).find(
          variant => variant && variant.id === filter.id
        ) !== undefined;
    }
  },
  watch: {
    products (val) {
      if (isServer) return
      const listOfSkus = val.map(product => product.parentSku)
      this.getReviewsSummary({ skus: listOfSkus })
    },
    selectedSortOrder (val) {
      this.changeSortOrder(val)
      if (this.currentPage > 1) {
        this.changePage();
      }
    },
    sortOrder () {
      if (this.currentPage > 1) {
        this.changePage();
      }
    },
    loading (val) {
      if (isServer) return
      if (!val) this.$bus.$emit('plp-on-enter', this.fullProducts)
    },
    $route: {
      immediate: true,
      handler (to, from) {
        if (to.query.page) {
          this.changePage(parseInt(to.query.page) || 1);
        }
        const recommendedSortingRegex = /(category_[0-9]*_position)/
        const isCurrentlySortingByRecommended = recommendedSortingRegex.test(this.selectedSortOrder)
        if (!isCurrentlySortingByRecommended) {
          // this.selectedSortOrder = this.sortOrder
          return
        }
        const doesOptionExist = this.sortOptions.find(option => option.id === this.selectedSortOrder)
        if (doesOptionExist) return
        const recommendedSortingOption = this.sortOptions.find(option => recommendedSortingRegex.test(option.id))
        this.selectedSortOrder = recommendedSortingOption.id
      }
    }
  },
  async asyncData ({ store, route, context }) {
    // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    if (context) context.output.cacheTags.add('category')
    await composeInitialPageState(store, route);
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) next();
    // SSR no need to invoke SW caching here
    else if (!from.name) {
      // SSR but client side invocation, we need to cache products and invoke requests from asyncData for offline support
      next(async vm => {
        vm.loading = true;
        await composeInitialPageState(vm.$store, to, true);
        await vm.$store.dispatch('category-next/cacheProducts', { route: to }); // await here is because we must wait for the hydration
        vm.loading = false;
      });
    } else {
      // Pure CSR, with no initial category state
      next(async vm => {
        vm.loading = true;
        vm.$store.dispatch('category-next/cacheProducts', { route: to });
        vm.loading = false;
      });
    }
  },
  mounted () {
    this.loadMoreProducts()
    this.unsubscribeFromStoreAction = this.$store.subscribeAction(action => {
      if (action.type === 'category-next/loadAvailableFiltersFrom') {
        this.aggregations = action.payload.aggregations;
      }
    });
    this.selectedSortOrder = this.sortOrder
    this.$bus.$on('product-after-list', this.initPagination);
    window.addEventListener('resize', this.getBrowserWidth);
    this.getBrowserWidth();
    this.loadRetailProducts();
  },
  beforeDestroy () {
    this.unsubscribeFromStoreAction();
    this.$bus.$off('product-after-list', this.initPagination);
    window.removeEventListener('resize', this.getBrowserWidth);
  },
  methods: {
    loadMoreProducts () {
      this.loadingProductsStarted = false;
      window.addEventListener('scroll', () => {
        if (this.$refs.productsList && window.scrollY >= (this.$refs.productsList.clientHeight - 350) && !this.loadingProductsStarted) {
          if (this.currentPageMobile >= this.totalPages) return;
          this.loadingProductsStarted = true;
          this.onBottomScroll();
          this.loadingProductsStarted = false;
        }
      })
    },
    ...mapActions('stamped-io', {
      getReviewsSummary: 'getSummary'
    }),
    changeCategory () {
      this.currentPage = 1
    },
    getBrowserWidth () {
      return (this.browserWidth = window.innerWidth);
    },
    loadMoreCategories () {
      this.onBottomScroll()
    },
    async onBottomScroll () {
      if (!this.isLazyLoadingEnabled || this.loadingProducts) {
        return;
      }
      this.loadingProducts = true;
      await this.$store.dispatch('category-next/loadMoreCategoryProducts');
      this.loadingProducts = false;
    },
    async changePage (page = this.currentPage) {
      if (!isServer) {
        document?.getElementById('category')?.scrollIntoView({ behavior: 'smooth' })
      }

      const start = (page - 1) * THEME_PAGE_SIZE;

      if (
        start < 0 ||
        start >= this.getCategoryProductsTotal ||
        this.getCategoryProductsTotal < THEME_PAGE_SIZE
      ) {
        return;
      }

      const { includeFields, excludeFields, sort } = config.entities.productList;
      const { filters } = this.getCurrentSearchQuery;
      const filterQuery = buildFilterProductsQuery(
        this.getCurrentCategory,
        filters
      );

      const searchResult = await quickSearchByQuery({
        query: filterQuery,
        sort: this.selectedSortOrder || sort,
        start: start,
        size: THEME_PAGE_SIZE,
        includeFields: includeFields,
        excludeFields: excludeFields
      });

      this.getMoreCategoryProducts = await this.$store.dispatch(
        'category-next/processCategoryProducts',
        {
          products: searchResult.items,
          filters: filters
        }
      );
      this.currentPage = page;
    },
    initPagination () {
      this.currentPage = parseInt(this.$route.query.page) || 1;
    },
    changeSortOrder (sortOrder) {
      const sortOrderFromConfig = `${config.products.defaultSortBy.attribute}:${config.products.defaultSortBy.order}`
      let defaultSortOrder = this.getCurrentCategory?.default_sort_by || sortOrderFromConfig

      if (defaultSortOrder === 'position') {
        defaultSortOrder = `category_${this.categoryId}_position`
      }

      if (sortOrder === undefined || sortOrder === 'undefined') {
        sortOrder = defaultSortOrder
      }

      const isFilterEmpty = Object.keys(this.getCurrentSearchQuery.filters).length < 1;
      let payload = []

      sortOrder = sortOrder === 'position' ? `category_${this.categoryId}_position` : sortOrder

      if (this.getCurrentSearchQuery.sort !== sortOrder) {
        payload = [
          { type: 'sort', id: sortOrder },
          { type: 'page', id: this.currentPage, single: true }
        ];
      }

      this.$store.dispatch('category-next/switchSearchFilters', { filterVariants: payload, isReset: sortOrder === defaultSortOrder && isFilterEmpty && this.currentPage === 1 });
      this.selectedSortOrder = sortOrder;
    },
    changeFilter (filter) {
      let payload = [
        filter,
        { type: 'page', id: '1', single: true }
      ];

      this.$store.dispatch('category-next/switchSearchFilters', { filterVariants: payload });
    },
    clearAllFilters () {
      this.$store.dispatch('category-next/resetSearchFilters');
    },
    getFilterCount (filter) {
      const aggregations = [
        `agg_range_${filter.type}`,
        `agg_terms_${filter.type}`,
        `agg_terms_${filter.type}_options`
      ];

      return aggregations
        .reduce((result, aggregation) => {
          const bucket =
            this.aggregations &&
            this.aggregations[aggregation] &&
            this.aggregations[aggregation].buckets.find(
              bucket => String(bucket.key) === String(filter.id)
            );

          return bucket ? result + bucket.doc_count : result;
        }, 0);
    },
    isCategoryActive (category) {
      if (!this.getCurrentCategory.path) {
        return false;
      }

      // The 'View all' sub-category (always at position 0) should be marked as active only if it exactly matches current category path,
      // but all other sub-categories will be marked as active when current category path belongs to them.
      return category.position === 0
        ? this.getCurrentCategory.path === category.path
        : this.getCurrentCategory.path.startsWith(category.path);
    },
    isOnWishlist (product) {
      return this.$store.getters['wishlist/isOnWishlist'](product)
    },
    toggleWishlist (product) {
      this.isOnWishlist(product) ? this.removeProductFromWhishList(product) : this.addProductToWhishlist(product);
    },
    addProductToWhishlist (product) {
      this.addToWishlist(product);
    },
    removeProductFromWhishList (product) {
      this.removeFromWishlist(product);
    },
    mapSortOptionToLabel (option) {
      const attributes = config.products.sortByAttributes;
      const result = Object.keys(attributes).find((key) => attributes[key].indexOf(option) > -1);

      return result || option
    },
    prepareLinks () {
      const storeView = currentStoreView();
      const storeCode = currentStoreView().storeCode || config.defaultStoreCode;
      const baseUrl = config.storeViews[storeCode].url;
      let filteredParams = getCanonicalOptionsFromRouteParams(this.$route.params)
      const url = baseUrl + formatCategoryLink(this.getCurrentCategory, storeView.storeCode)
      let links = [{ rel: 'canonical', href: addCanonicalParams(url, filteredParams) }]
      this.products.forEach((product) => {
        links.push({ rel: 'preload', as: 'image', href: product.image })
      })
      return links
    },
    async loadRetailProducts () {
      this.recommendedCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.categoryPageRecommendedId);
    }
  },
  metaInfo () {
    const {
      meta_title,
      meta_description,
      name
    } = this.getCurrentCategory;
    const meta = meta_description
      ? [
        {
          vmid: 'description',
          name: 'description',
          content: htmlDecode(meta_description)
        }
      ]
      : [];

    const metaTitle = meta_title || name;
    let result = metaTitle;

    if (this.currentPage > 1) {
      result = i18n.t('{{category}} - Page {{page}}');
      result = result.replace('{{category}}', metaTitle);
      result = result.replace('{{page}}', this.currentPage);
    }

    return {
      title: htmlDecode(result),
      meta,
      link: this.prepareLinks()
    };
  }
};
</script>

<style lang="scss">
#category {
  .category-top-description {
    h1 {
      text-align: center;
      text-transform: uppercase;
      font-weight: var(--font-weight--medium);
    }
    p {
      margin-bottom: 0;
      line-height: 1.27
    }
    a.read-more {
      color: red;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#category {
  box-sizing: border-box;
  @include for-desktop {
    max-width: var(--container-width);
    margin: 0 auto;
  }
}
.metaDesc {
  padding-top: 30px;
  text-align: center;
  font-weight: 600;
}
.main {
  &.section {
    padding: var(--spacer-xs);
    @include for-desktop {
      padding: 0;
    }
  }
}
.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base) var(--spacer-sm);
}
.navbar {
  position: relative;
  display: flex;
  border: 1px solid var(--c-light-variant);
  border-width: 0 0 1px 0;
  @include for-desktop {
    border-width: 1px 0 1px 0;
  }
  &.section {
    color: var(--c-secondary-variant);
    padding: var(--spacer-sm);
    @include for-desktop {
      padding: 0;
    }
  }
  &__aside,
  &__main {
    display: flex;
    align-items: center;
    padding: var(--spacer-sm) 0;
  }
  &__aside {
    padding: var(--spacer-sm) var(--spacer-sm);
    border: 1px solid var(--c-light-variant);
    border-width: 0 1px 0 0;
    width: 12rem;

  }
  &__main {
    flex: 1;
    padding: 0;
    justify-content: space-between;
    @include for-desktop {
      justify-content: flex-start;
      padding: var(--spacer-xs) var(--spacer-xl);
    }
  }
  &__title {
    --heading-title-font-weight: var(--font-weight--semibold);
    --heading-title-font-size: calc(var(--font-size--lg) * 1.2);
  }
  &__filters-button {
    --button-color: var(--c-secondary-variant);
    font-weight: var(--font-weight--normal);
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-decoration: none;
    order: 1;
    &.sort-by-btn {
      order: 4;
    }
    svg {
      fill: var(--c-text-muted);
      transition: fill 150ms ease;
    }
    &:hover {
      svg {
        fill: var(--c-primary);
      }
    }
    @include for-mobile {
      --button-text-transform: uppercase;
      font-size: var(--font-size--xs);
      &.sort-by__button {
        order: 1;
      }
    }
  }
  &__filters-icon {
    margin: 0 var(--spacer-xs) 0 0;
  }
  &__label {
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight--normal);
    color: var(--c-black-secondary);
    margin: 0 var(--spacer-2xs) 0 0;
    @include for-mobile {
      font-size: var(--font-size--xs);
    }
  }
  &__select {
    --select-padding: 0 var(--spacer-lg) 0 var(--spacer-2xs);
    --select-margin: 0;
    --select-selected-padding: 0 var(--spacer-xl) 0 0;
  }
  &__sort {
    display: flex;
    align-items: center;
    margin: 0;
    order: 2;
    @include for-desktop {
      margin: 0 0 0 var(--spacer-2xl);
    }
    .sort-by, ::v-deep .sf-component-select {
      --component-select-option-padding: calc(var(--spacer-xs) * 1.6) var(--spacer-xs) var(--spacer-xs);
      top: calc(var(--spacer-sm) * 0.9);
      &.is-active {
        --chevron-color: currentColor;
      }
      &__dropdown {
        white-space: nowrap;
        width: initial;
        min-width: 150px;
        @include for-mobile {
          width: 100vw;
        }
      }
      &__option {
        cursor: pointer;
      }
      &__selected {
        font-family: var(--font-family--primary);
        font-weight: var(--font-weight--semibold);
        font-size: var(--font-size--base);
        display: none;
        @include for-desktop {
          display: flex;
        }
      }
      .sf-chevron {
        top: calc(var(--spacer-base) * 1.05);
        display: none;
        @include for-desktop {
          display: flex;
        }
      }
    }
  }
  &__counter {
    font-family: var(--font-family--primary);
    margin: 0 0 0 var(--spacer-xs);
    order: 3;
    @include for-desktop {
      margin: auto 0 auto auto;
    }
  }
  &__view {
    display: flex;
    align-items: center;
    margin: 0 var(--spacer-xl);
    @include for-desktop {
      margin: 0 0 0 var(--spacer-2xl);
    }
    &-icon {
      cursor: pointer;
    }
    &-label {
      margin: 0 var(--spacer-sm) 0 0;
      font: var(--font-weight--medium) var(--font-size--xs) / 1.6 var(--font-family--primary);
      text-decoration: none;
    }
  }
}
.main {
  display: flex;
}
.sidebar {
  padding: var(--spacer-sm);
  border: 1px solid var(--c-light-variant);
  border-width: 0 1px 0 0;
  width: 12rem;
  @include for-desktop {
    .sf-accordion-item {
      --accordion-item-header-font-size: calc(var(--font-size--sm) * 1.2);
      --accordion-item-header-font-weight: var(--font-weight--semibold);
      --accordion-item-header-color: var(--c-black);
      &__content {
        .sf-menu-item {
          font-size: var(--font-size--base);
          &__label {
            width: 88%;
            text-align: left;
          }
        }
      }
      &__header {
        text-align: left;
        font-weight: var(--font-weight--medium);
        font-size: var(--font-size--base);
        word-break: break-all;
      }
    }
  }
}
.sidebar-filters {
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
  }
}
.list {
  --menu-item-font-size: var(--font-size--sm);
  &__item {
    --link-text-decoration: none;
    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  width: 100%;
  .bottom-description {
    margin-top: 35px;
    p {
      line-height: 1.3;
    }
  }
  .m-load-more {
    margin: var(--spacer-2xl) 0 var(--spacer-xl);
  }

  &__grid,
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__grid {
    justify-content: flex-start;
  }
  &__product-card {
    --price-justify-content: center;
    --price-align-items: center;
    flex: 1 1 50%;
    max-width: 50%;
  }
  &__slide-enter {
    opacity: 0;
  }
  &__slide-enter-active {
    transition: all 0.5s ease;
    transition-delay: calc(0.1s * var(--index));
  }
  @include for-desktop {
    margin: var(--spacer-sm) 0 0 var(--spacer-sm);
    &__pagination {
      display: flex;
      justify-content: center;
      margin: var(--spacer-xl) 0 0 0;
      --pagination-item-color: var(--c-gray-variant);
      ::v-deep .sf-pagination {
        &__item.points {
          color: var(--c-gray-variant);
        }
        &__item.current {
          color: var(--c-secondary);
        }
      }
    }
    &__product-card {
      padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-base);
      flex: 1 1 25%;
      max-width: 25%;
    }
    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }
}
.products-recommended {
  margin: var(--spacer-lg) var(--spacer-sm) 0;
}
.filters {
  &__title {
    --heading-title-font-size: var(--font-size--lg);
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;
    &:first-child {
      margin: 0 0 var(--spacer-xs) 0;

      @include for-desktop {
        margin-top: calc(var(--spacer-base) + var(--spacer-xl));
      }
    }
  }
  &__color {
    margin: var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) 0;
    border: 1px solid var(--c-light);
  }
  &__item {
    --filter-label-color: var(--c-secondary-variant);
    --filter-count-color: var(--c-secondary-variant);
    --checkbox-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid var(--c-light);
    &:last-child {
      border-bottom: 0;
    }
    @include for-desktop {
      --checkbox-padding: 0;
      margin: var(--spacer-sm) 0;
      border: 0;
      padding: 0;
    }
  }
  &__accordion-item {
    --accordion-item-content-padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }
  &__buttons {
    margin: var(--spacer-sm) 0;
  }
  &__button-clear {
    --button-background: var(--c-light);
    --button-color: var(--c-dark-variant);
    margin: var(--spacer-xs) 0 0 0;
  }
}
</style>
